import Loader from '../../../../components/Common/Loader';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';

import {postRoute, updateRoute} from '../../../../helpers/Api/Occurences/routes';

import {getExhibId} from '../../../../constants/utils';
import {getDeeplink} from "../../../../helpers/Api/common_apis";
import axios from "axios";
import {API_URL} from "../../../../constants/apiurl";

const axiosHeaderConfig = token => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
    }
});

class RouteDetails extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            rid: this.props.routeid === 'new' ? '' : this.props.routeid,
            status: this.props.routeid === 'new' ? '' : this.props.routedetails.route.status,
            statuslist: [],
            vstatus: '',
            title: this.props.routeid === 'new' ? '' : this.props.routedetails.route.title,
            vtitle: '',
            author: this.props.routeid === 'new' ? '' : this.props.routedetails.route.author,
            vauthor: '',
            description: this.props.routeid === 'new' ? '' : this.props.routedetails.route.description,
            vdescription: '',
            interactionsAudio: this.props.routeid === 'new' ? false : this.props.routedetails.route.interactionsAudio,
            interactionsVideo: this.props.routeid === 'new' ? false : this.props.routedetails.route.interactionsVideo,
            interactionsAr: this.props.routeid === 'new' ? false : this.props.routedetails.route.interactionsAr,
            interactionsReaction: this.props.routeid === 'new' ? false : this.props.routedetails.route.interactionsReaction,
            interactionsToken: this.props.routeid === 'new' ? false : this.props.routedetails.route.interactionsToken,
            selectedTypes: this.props.routeid === 'new' ? [] : this.props.routedetails.types === null ? [] : this.props.routedetails.types,
            vtype: '',
            paymentAccessType: this.props.routeid === 'new' ? '' : this.props.routedetails.route.paymentAccessType,
            vpaymentAccessType: '',
            paymentAccessTypeList: [],
            editStatus: false,
            viewStatus: false,
            setPageStatus: '',
            newStatus: false,
            loading: false,
            showAlert: false,
            alertMessage: '',

            //external links
            donationLinkText: this.props.routeid === 'new' ? '' : this.props.routedetails.route.donationLinkText,
            redeemLinkText: this.props.routeid === 'new' ? '' : this.props.routedetails.route.redeemLinkText,
            donationLinkUrl: this.props.routeid === 'new' ? '' : this.props.routedetails.route.donationLinkUrl,
            redeemLinkUrl: this.props.routeid === 'new' ? '' : this.props.routedetails.route.redeemLinkUrl,
            iapAndroidId: this.props.routeid === 'new' ? '' : this.props.routedetails.route.iapAndroid,
            iapIosId:  this.props.routeid === 'new' ? '' : this.props.routedetails.route.iapIos
        };
    }

    componentDidMount() {
        // API Calls
        this.getStatus();
        this.getPaymentAccessTypes();
        this.setState({loading: false});

        const {
            match: {params}
        } = this.props;

        if (params.idd === 'view') {
            this.setState({viewStatus: true, setPageStatus: 'View'});
        }
        if (params.idd === 'post') {
            this.setState({newStatus: true, setPageStatus: 'Create'});
        }
        if (params.idd === 'edit') {
            this.setState({setPageStatus: 'Edit'});
        }

        if (params.id !== 'new') {
            this.setState({editStatus: true});
        } else {
            this.setState({editStatus: false});
        }
    }

    callbackChild = () => {
        this.props.parentCallbackRList({showDetails: false, showSteps: false});
    };

    async submitForm(e) {
        e.preventDefault();

        if (this.state.status === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vstatus: 'This field is required!'});
        }

        if (this.state.title === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vtitle: 'This field is required!'});
        }

        if (this.state.author === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vauthor: 'This field is required!'});
        }
        if (this.state.description === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vdescription: 'This field is required!'});
        }

        if (this.state.paymentAccessType === '') {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vpaymentAccessType: 'This field is required!'});
        }

        if (this.state.selectedTypes.length === 0) {
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.setState({vtype: 'This field is required!'});
        }

        if (this.state.status !== '' && this.state.title !== '' && this.state.author !== '' && this.state.description !== '' && this.state.paymentAccessType !== '' && this.state.selectedTypes.length > 0) {
            console.log('ok..... ');
            this.postRouteData();
        }
    }

    async postRouteData() {

        let postData = {
            id: +this.state.rid,
            status: this.state.status,
            title: this.state.title,
            author: this.state.author,
            description: this.state.description,
            paymentAccessType: this.state.paymentAccessType,
            interactionsAudio: this.state.interactionsAudio,
            interactionsVideo: this.state.interactionsVideo,
            interactionsAr: this.state.interactionsAr,
            interactionsReaction: this.state.interactionsReaction,
            interactionsToken: this.state.interactionsToken,
            deepLink: null,
            iapAndroid: this.state.iapAndroidId,
            iapIos: this.state.iapIosId,
            donationLinkText: this.state.donationLinkText,
            redeemLinkText: this.state.redeemLinkText,
            donationLinkUrl: this.state.donationLinkUrl,
            redeemLinkUrl: this.state.redeemLinkUrl,
        }

        let eid = getExhibId();

        if (eid.id === undefined || eid === undefined) {
            return;
        } else {
            this.setState({loading: true, id: eid.id});
            if (this.props.routeid !== 'new') {
                if (this.props.routedetails.deepLink == null) {
                    postData.deepLink = await this.getDeeplinkData(postData.id);
                }

                const response = await updateRoute(this.props.routeid, postData, this.state.selectedTypes);
                if (response.error) {
                    this.setState({loading: false});
                    console.log('error ---- ' + JSON.stringify(response));
                } else {
                    this.setState({loading: false});
                    this.callbackChild();
                }
            } else {
                try {
                    const create = await postRoute(eid.id, postData, this.state.selectedTypes);
                    const r = create.data;
                    const createdDeeplink = await this.getDeeplinkData(r.id);
                    r.deepLink = createdDeeplink.shortLink
                    let updateData = {
                        id: r.route.id,
                        status: r.route.status,
                        title: r.route.title,
                        author: r.route.author,
                        description: r.route.description,
                        paymentAccessType: r.route.paymentAccessType,
                        interactionsAudio: r.route.interactionsAudio,
                        interactionsVideo: r.route.interactionsVideo,
                        interactionsAr: r.route.interactionsAr,
                        interactionsReaction: r.route.interactionsReaction,
                        interactionsToken: r.route.interactionsToken,
                        deepLink: r.deepLink,
                        iapAndroid: r.iapAndroid,
                        iapIos: r.iapIos,
                        donationLinkText: this.state.donationLinkText,
                        redeemLinkText: this.state.redeemLinkText,
                        donationLinkUrl: this.state.donationLinkUrl,
                        redeemLinkUrl: this.state.redeemLinkUrl,
                    };
                    await updateRoute(r.route.id, updateData, this.state.selectedTypes);
                } catch (e) {
                    console.log('error ---- ' + JSON.stringify(create));
                } finally {
                    this.setState({loading: false});
                    this.callbackChild();
                }
            }
        }
    }

    async getDeeplinkData(id) {
        let postdata = {
            type: 'Route',
            reference: id,
            contentType: null
        };

        const data = await getDeeplink(postdata);
        if (data.error) {
            console.log('error ---- ' + JSON.stringify(data));
            return '';
        }

        return data.data.shortLink;
    }

    getStatus = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({loading: true});

        axios.get(API_URL + '/tt/exhibition/route/status', axiosconf).then(response => {
            this.setState({
                statuslist: response.data
            });
            this.getVenues();
        });
    };

    getPaymentAccessTypes = () => {
        let obj = this.getAuthDetails();
        let axiosconf = axiosHeaderConfig(obj.access_token);
        this.setState({loading: true});

        axios.get(API_URL + '/tt/exhibition/route/payment/access/types', axiosconf).then(response => {
            this.setState({
                paymentAccessTypeList: response.data
            });
        });
    };


    getAuthDetails = () => {
        if (localStorage.getItem('authUser')) {
            const obj = JSON.parse(localStorage.getItem('authUser'));
            return obj;
        }
    };

    render() {
        return (
            <React.Fragment>
                <Card>
                    <div className="text-center mt-0">
                        <h5>Exhibition | Route Details</h5>
                    </div>

                    <div style={{display: 'flex'}}>
                        <button onClick={() => this.callbackChild()} style={{marginLeft: 'auto'}} type="button"
                                className="btn btn-primary">
                            <i className="bx bx-arrow-back font-size-16 align-middle me-2"></i>
                            Back to route list
                        </button>
                    </div>
                    {this.state.loading ? (
                        <Loader color={'green'} loading={this.state.loading}/>
                    ) : (
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        {this.state.showAlert ? (
                                            <SweetAlert warning title={''}
                                                        onConfirm={() => this.setState({showAlert: false})}>
                                                {this.state.alertMessage}
                                            </SweetAlert>
                                        ) : null}

                                        <CardTitle className="mb-4">
                                            {this.state.editStatus && (
                                                <span
                                                    className={this.state.status === 'Published' ? 'bg-success badge bg-success' : 'bg-warning badge bg-warning'}>{this.state.status}</span>
                                            )}
                                        </CardTitle>
                                        <Form className="form" onSubmit={e => this.submitForm(e)}>
                                            <Row>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Title</Label>
                                                        <Input
                                                            readOnly={this.state.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Enter Title"
                                                            name="title"
                                                            value={this.state.title}
                                                            onChange={e =>
                                                                this.setState({
                                                                    title: e.target.value,
                                                                    vtitle: ''
                                                                })
                                                            }
                                                        />
                                                        {this.state.vtitle !== '' &&
                                                            <span style={{color: 'red'}}>{this.state.vtitle}</span>}
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-firstname-Input">Author</Label>
                                                        <Input
                                                            readOnly={this.state.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Enter Author"
                                                            name="author"
                                                            value={this.state.author}
                                                            onChange={e =>
                                                                this.setState({
                                                                    author: e.target.value,
                                                                    vauthor: ''
                                                                })
                                                            }
                                                        />
                                                        {this.state.vauthor !== '' &&
                                                            <span style={{color: 'red'}}>{this.state.vauthor}</span>}
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Select Types</Label>
                                                        <br/>

                                                        <select
                                                            disabled={this.state.viewStatus}
                                                            value={''}
                                                            onChange={e => {
                                                                if (e.target.options[e.target.selectedIndex].text !== 'Select Types') {
                                                                    let found = this.state.selectedTypes.find(a => a === e.target.value);
                                                                    if (!found) {
                                                                        this.setState({
                                                                            selectedTypes: [...this.state.selectedTypes, e.target.value],
                                                                            vtype: ''
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                            className="form-select"
                                                        >
                                                            <option value="">Select Types</option>
                                                            <option value="Off Site">Off Site</option>
                                                            <option value="On Site">On Site</option>
                                                        </select>
                                                        <br/>
                                                        {this.state.selectedTypes?.map((item, idx) => (
                                                            <button
                                                                style={{
                                                                    marginBottom: '5px',
                                                                    marginLeft: '2px'
                                                                }}
                                                                key={idx}
                                                                onClick={e => {
                                                                    const reducedArr = this.state.selectedTypes.filter((item, itemIndex) => {
                                                                        return itemIndex !== idx;
                                                                    });

                                                                    this.setState({
                                                                        selectedTypes: reducedArr
                                                                    });
                                                                }}
                                                                type="button"
                                                                className="btn btn-light btn-label"
                                                            >
                                                                <i className="dripicons-tag-delete label-icon "></i>
                                                                {item}
                                                            </button>
                                                        ))}
                                                        {this.state.vtype !== '' &&
                                                            <span style={{color: 'red'}}>{this.state.vtype}</span>}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="mt-4 mb-2">
                                                <Col md={2}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Payment Access Type
                                                            *</Label>
                                                        <select
                                                            disabled={this.state.viewStatus}
                                                            value={this.state.paymentAccessType}
                                                            onChange={e => this.setState({
                                                                paymentAccessType: e.target.value,
                                                                vpaymentAccessType: ''
                                                            })}
                                                            className="form-select">
                                                            <option value="">Select Type</option>
                                                            {this.state.paymentAccessTypeList.map((item, idx) => (
                                                                <option key={idx} value={item}>
                                                                    {item}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {this.state.vpaymentAccessType !== '' && <span
                                                            style={{color: 'red'}}>{this.state.vpaymentAccessType}</span>}
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">In-App Purchase ID
                                                            (Android)</Label>
                                                        <Input
                                                            readOnly={this.props.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Enter Android IAP ID"
                                                            name="iapAndroidId"
                                                            value={this.state.iapAndroidId}
                                                            onChange={e => this.setState({iapAndroidId: e.target.value})}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">In-App Purchase ID
                                                            (iOS)</Label>
                                                        <Input
                                                            readOnly={this.props.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Enter iOS IAP ID"
                                                            name="iapIosId"
                                                            value={this.state.iapIosId}
                                                            onChange={e => this.setState({iapIosId: e.target.value})}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="mt-4">
                                                <Col md={4}>
                                                    <div>
                                                        <Label htmlFor="formrow-firstname-Input">Make a Donation Link Text</Label>
                                                        <Input
                                                            readOnly={this.props.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Enter Link Text"
                                                            name="donationLinkText"
                                                            value={this.state.donationLinkText}
                                                            onChange={e => this.setState({donationLinkText: e.target.value})}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div>
                                                        <Label
                                                            htmlFor="formrow-firstname-Input">Make a Donation URL</Label>
                                                        <Input
                                                            readOnly={this.props.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Enter URL"
                                                            name="donationLinkUrl"
                                                            value={this.state.donationLinkUrl}
                                                            onChange={e => this.setState({donationLinkUrl: e.target.value})}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="mt-4 mb-2">
                                                <Col md={4}>
                                                    <div>
                                                        <Label htmlFor="formrow-firstname-Input">Redeem your Token Link Text</Label>
                                                        <Input
                                                            readOnly={this.props.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Enter Link Text"
                                                            name="redeemLinkText"
                                                            value={this.state.redeemLinkText}
                                                            onChange={e => this.setState({redeemLinkText: e.target.value})}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div>
                                                        <Label
                                                            htmlFor="formrow-firstname-Input">Redeem your Token URL</Label>
                                                        <Input
                                                            readOnly={this.props.viewStatus}
                                                            type="text"
                                                            className="form-control"
                                                            id="formrow-firstname-Input"
                                                            placeholder="Enter URL"
                                                            name="redeemLinkUrl"
                                                            value={this.state.redeemLinkUrl}
                                                            onChange={e => this.setState({redeemLinkUrl: e.target.value})}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="mt-5">
                                                <Col>
                                                    <Label htmlFor="formrow-firstname-Input">Interactions</Label>

                                                    <Row>
                                                        <Col>
                                                            <FormGroup switch>
                                                                <Input
                                                                    readOnly={this.props.viewStatus}
                                                                    type="switch"
                                                                    checked={this.state.interactionsAudio}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            interactionsAudio: !this.state.interactionsAudio
                                                                        })
                                                                    }
                                                                />
                                                                <Label check>Audio</Label>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col>
                                                            <FormGroup switch>
                                                                <Input
                                                                    readOnly={this.props.viewStatus}
                                                                    type="switch"
                                                                    checked={this.state.interactionsVideo}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            interactionsVideo: !this.state.interactionsVideo
                                                                        })
                                                                    }
                                                                />
                                                                <Label check>Video</Label>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col>
                                                            <FormGroup switch>
                                                                <Input
                                                                    readOnly={this.props.viewStatus}
                                                                    type="switch"
                                                                    checked={this.state.interactionsAr}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            interactionsAr: !this.state.interactionsAr
                                                                        })
                                                                    }
                                                                />
                                                                <Label check>AR</Label>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col>
                                                            <FormGroup switch>
                                                                <Input
                                                                    readOnly={this.props.viewStatus}
                                                                    type="switch"
                                                                    checked={this.state.interactionsReaction}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            interactionsReaction: !this.state.interactionsReaction
                                                                        })
                                                                    }
                                                                />
                                                                <Label check>Reaction</Label>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col>
                                                            <FormGroup switch>
                                                                <Input
                                                                    readOnly={this.props.viewStatus}
                                                                    type="switch"
                                                                    checked={this.state.interactionsToken}
                                                                    onChange={e =>
                                                                        this.setState({
                                                                            interactionsToken: !this.state.interactionsToken
                                                                        })
                                                                    }
                                                                />
                                                                <Label check>Token</Label>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            <Row className="mt-4">
                                                <Col md={12}>
                                                    <Label htmlFor="formrow-firstname-Input">Description</Label>

                                                    <textarea
                                                        readOnly={this.state.viewStatus}
                                                        type="textarea"
                                                        rows="7"
                                                        className="form-control"
                                                        id="basicpill-expiration-input13"
                                                        placeholder="Enter Description"
                                                        value={this.state.description}
                                                        onChange={e =>
                                                            this.setState({
                                                                description: e.target.value,
                                                                vdescription: ''
                                                            })
                                                        }
                                                    />
                                                    {this.state.vdescription !== '' &&
                                                        <span style={{color: 'red'}}>{this.state.vdescription}</span>}
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col md={6}>
                                                    <div className="mb-4">
                                                        <Label htmlFor="formrow-firstname-Input">Status *</Label>
                                                        <select
                                                            disabled={this.state.viewStatus}
                                                            value={this.state.status}
                                                            onChange={e => this.setState({
                                                                status: e.target.value,
                                                                vstatus: ''
                                                            })}
                                                            className="form-select"
                                                        >
                                                            <option value="">Select Status</option>
                                                            {this.state.statuslist.map((item, idx) => (
                                                                <option key={idx} value={item}>
                                                                    {item}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {this.state.vstatus !== '' &&
                                                            <span style={{color: 'red'}}>{this.state.vstatus}</span>}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="text-center mt-4">
                                                <button disabled={this.state.viewStatus} type="submit"
                                                        className="btn btn-primary w-md">
                                                    Save
                                                </button>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Card>
            </React.Fragment>
        );
    }
}

RouteDetails.propTypes = {
    className: PropTypes.any,
    match: PropTypes.object,
    history: PropTypes.any,
    viewStatus: PropTypes.bool,
    parentCallbackRList: PropTypes.func,
    routeid: PropTypes.number,
    routedetails: PropTypes.object,
    routelist: PropTypes.array
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RouteDetails));
